import eqptRouter from "@/router/modules/eqpt";

export default {
  login: {
    title: "用户登录",
    loginBtn: "登录",
    usernameRule: "用户名为必填项",
    passwordRule: "密码不能少于6位",
  },
  tagsView: {
    refresh: "刷新",
    closeRight: "关闭右侧",
    closeOther: "关闭其他",
  },
  universal: {
    confirm: "确定",
    cancel: "取消",
  },
  route: {
    home: "首页",
    waterData: "用水数据",
    waterLatest: "最新数据",
    waterHistory: "历史数据",
    errorData: "异常告警",
    connectError: "通讯异常",
    dataError: "数据异常",
    userHome: "",
    profile: "个人中心",
    user: "用户",
    userInfo: "员工信息",
    eqptControl: "设备控制",
    valveControl: "阀控",
    eqptInfo: "数据详情",
    valveResume: "阀控履历",
    showEqptData: "设备数据",
    // 数据管理
    dataHome: "数据管理",
    dataManage: "数据管理",
    hotData: "热表",
    temValveData: "温控阀",
    balanceValveData: "平衡阀",
    nbThermometerData: "NB温显器",
    pressureData: "压力数据",
    dataAnalysis: "数据分析",
    hotNewestLindom: "热表最新数据",
    hotHistoryLindom: "热表历史数据",
    hotNewest: "热表最新数据",
    hotHistory: "热表历史数据",
    temValveNewest: "温控阀最新数据",
    temValveHistory: "温控阀历史数据",
    temValveNewestLindom: "温控阀最新数据",
    temValveHistoryLindom: "温控阀历史数据",
    balanceValveNewest: "平衡阀最新数据",
    balanceValveHistory: "平衡阀历史数据",
    balanceValveNewestLindom: "平衡阀最新数据",
    balanceValveHistoryLindom: "平衡阀历史数据",
    nbThermometerNewestLindom: "NB温显器最新数据",
    nbThermometerHistoryLindom: "NB温显器历史数据",
    nbThermometerNewest: "NB温显器最新数据",
    nbThermometerHistory: "NB温显器历史数据",
    pressureNewest: "压力最新数据",
    pressureHistory: "压力历史数据",
    pressureNewestLindom: "压力最新数据",
    pressureHistoryLindom: "压力历史数据",
    statisticalAnalysis: "统计分析",
    listBrowse: "列表浏览",
    graphicAnalysis: "图形分析",
    pressureDisplayData: "压力显示器",
    pressureDisplayNewest: "压力最新数据",
    pressureDisplayHistory: "压力历史数据",
    pressureDisplayNewestLindom: "压力最新数据",
    pressureDisplayHistoryLindom: "压力历史数据",
    flowmeterData: "流量计",
    flowmeterNewest: "流量计最新数据",
    flowmeterHistory: "流量计历史数据",
    flowmeterNewestLindom: "流量计最新数据",
    flowmeterHistoryLindom: "流量计历史数据",
    // 设备运维
    eqptOpManage: "设备运维",
    hotMeterControl: "热表控制",
    temControlValveControl: "温控阀控制",
    balanceValveControl: "平衡阀控制",
    nbThermometerControl: "NB温显器控制",
    controlResume: "控制履历",
    valveResumeLindom: "控制履历lindorm",
    pressureDisplayControl: "压力显示器控制",
    flowmeterControl: "流量计控制",
    // 用热配置
    useHeat: "用热数据",
    useHeatNewData: "用热最新数据",
    useHeatHisData: "用热历史数据",
    useHeatConf: "用热配置",
    // 故障报警
    faultAlarmManage: "故障报警",
    hotMeter: "热表",
    hotMeterFault: "热表故障",
    hotMeterLastFault: "热表最新故障",
    temControlValve: "温控阀",
    temControlValveFault: "温控阀故障",
    temControlValveLastFault: "温控阀最新故障",
    balanceValve: "平衡阀",
    balanceValveFault: "平衡阀故障",
    balanceValveLastFault: "平衡阀最新故障",
    nbThermometer: "NB温显器",
    nbThermometerFault: "NB温显器故障",
    nbThermometerLastFault: "NB温显器最新故障",
    flowmeter: "流量计",
    flowmeterFault: "流量计故障",
    flowmeterLastFault: "流量计最新故障",
    pressureDisplay: "压力显示器",
    pressureDisplayFault: "压力显示器故障",
    pressureDisplayLastFault: "压力显示器最新故障",
    // 基础信息
    basicsManage: "基础信息",
    userManage: "用户管理",
    eqptManage: "设备管理",
    eqptWarehouse: "设备仓库",
    eqptRecycleBin: "设备回收站",
    excelImport: "Excel导入",
    collectorManage: "采集器管理",
    collectConf: "采集策略",
    textMessage: "短信策略",
    sendTextMessage: "发送短信",
    messageLog: "短信日志",
    eqptDisguiser: "设备伪装管理",
    eqptDockRecords: "设备对接信息记录",
    nbEqptManage: "nb设备管理",
    // 系统管理
    sysMain: "系统管理",
    organizationManage: "组织机构管理",
    exceptionManage: "异常管理",
    dictManage: "字典管理",
    messageManage: "短信管理",
    tenantManage: "租户管理",
    warnSetting: "警告设置",
    roleManage: "角色管理",
    operationManage: "操作管理",
    permissionManage: "许可管理",
    accountManage: "账户管理",
    protocolManage: "通讯协议管理",
    // 工单
    workOrderData: "工单中心",
    workOrderDisposition: "处置",
    workOrderVerify: "审核",
  },
  role: {
    buttonTxt: "新增角色",
    index: "序号",
    name: "名称",
    desc: "描述",
    action: "操作",
    assignPermissions: "分配权限",
    removeRole: "删除角色",
    dialogTitle: "新增角色",
    dialogRole: "角色名称",
    dialogDesc: "角色描述",
    updateRoleSuccess: "用户角色更新成功",
  },
  permission: {
    name: "权限名称",
    mark: "权限标识",
    desc: "权限描述",
  },
  dict: {
    addDictType: "添加字典类型",
    dictName: "字典名称",
    libraryCodePlaceholder: "请输入字典编码",
    libraryCodeRulesMessage: "字典编码为必填项",
    libraryNamePlaceholder: "请输入字典名称",
    libraryNameRulesMessage: "字典名称为必填项",
    libraryDescPlaceholder: "请输入字典描述",
  },
  excel: {
    importExcel: "excel 导入",
    exportExcel: "excel 导出",
    exportZip: "zip 导出",
    name: "姓名",
    mobile: "联系方式",
    avatar: "头像",
    role: "角色",
    openTime: "开通时间",
    action: "操作",
    show: "查看",
    showRole: "角色",
    defaultRole: "员工",
    addSuccess: "添加成功",
    modifySuccess: "修改成功",
    remove: "删除",
    removeSuccess: "删除成功",
    title: "导出为 excel",
    placeholder: "excel 文件名称",
    defaultName: "员工管理表",
    close: "取 消",
    confirm: "导 出",
    importSuccess: " 条员工数据导入成功",
    dialogTitle1: "确定要删除用户 ",
    dialogTitle2: " 吗？",
    roleDialogTitle: "配置角色",
    refresh: "刷新",
  },
  axios: {
    Requestfailedwithstatuscode404: "请求失败，状态代码为404",
    Requestfailedwithstatuscode500: "请求失败，状态代码为500",
    timeoutof5000msexceeded: "请求超时",
    OPERATE_SUCCESS: "操作成功",
  },
  request: {},
  showData: {
    simpleAddress: "地址",
    operateTime: "操作时间",
    operatorName: "操作人",
    readingTime: "读取时间",
    readingFlow: "正向累计流量",
    instantaneousFlow: "瞬时流量",
    workingHours: "工作时长",
    waterTemperature: "水温",
    waterPressure: "水压",
    purchaseTime: "购水/气次数日冻结",
    balance: "剩余金额日冻结",
    usageAmount: "表计数",
    usageAmountUnit: "表计数单位",
    zeroPaasSum: "过零金额",
    todayUnitPrice: "当日单价",
    todayUsageAmount: "当日使用量",
    workStatus: "设备状态",
    voltage: "电压",
    accruingAmount: "累计使用金额",
    userNo: "户号",
    signalQuality: "信号质量",
    reportCause: "上报原因",
    eqptId: "设备ID",
    eqptCode: "设备号",
    eqptDn: "设备口径",
    concentratorId: "集中器ID",
    concentratorCode: "集中器编码",
    eqptType: "设备类型",
    meterType: "抄表类型",
    eqptComProtocol: "设备通讯协议",
    eqptCardNo: "设备物联网卡号",
    eqptComMode: "设备通讯方式",
    eqptComPlatform: "通讯平台",
    reverseFlow: "反向累计流量",
    readingId: "主键",
    libraryDesc: "描述",
    libraryCode: "编码",
    libraryName: "名称",
    libraryCreatetime: "创建时间",
    buyAmount: "累计购水/气金额日冻结数据",
    todayUsageAmountUnit: "当日使用量单位",
    timeStart: "开始时间",
    timeEnd: "结束时间",
    dataHash: "数据hash值",
    valveStatus: "阀门状态",
    voltageStatus: "电压状态",
    exceptionDescription: "异常描述",
    exceptionContent: "异常内容",
    exceptionTime: "异常时间",
    exceptionDisposeStatus: "异常处置状态",
    exceptionInformStatus: "异常通知状态",
    exceptionInformTime: "异常通知次数",
    dailyWaterConsumption: "日用水量",
    productionBatch: "生产批次",
    eqptModel: "设备型号",
    unreportDays: "未报天数",
    nodeUserName: "户主姓名",
    nodeUserPhone: "户主电话",
    cmdId: "指令ID",
    sendTime: "发送时间",
    cmdApi: "API",
    apiParams: "参数",
    // cmdContent: '指令ID',
    cmdContent: "指令内容",
    sendStatus: "发送状态",
    responseContent: "响应内容",
    uuid: "命令唯一识别码",
    callbackContent: "回调内容",
    confirmTime: "确认时间",
    confirmStatus: "确认状态",
    callback: "回调",
    ser: "序列号",
    cmdType: "指令类型",
    cmdDescription: "指令描述",
    roomTemperature: "室内温度",
    roomTemperatureSet: "室内温度设置值",
    maxTemperatureSet: "最高设定温度",
    minTemperatureSet: "最低设定温度",
    controlPanelId: "面板地址(设备上)",
    openDegree: "开度",
    maxOpenDegreeSet: "最大开度",
    minOpenDegreeSet: "最小开度",
    waterInTemperature: "供水温度",
    waterOutTemperature: "回水温度",
    waterOutTemperatureSet: "回温设定值",
    runningMode: "运行模式",
    lockingStatus: "锁定状态",
    eqptTypeChild: "设备子类型",
    eqptComFeeValidity: "通讯费用有效期",
    oneLevelAddress: "一级地址",
    collectorCode: "采集器编号",
    collectorIotCardNo: "采集器物联网卡号",
    installationAddress: "安装地址",
    eqptFirm: "设备厂商",
    runningStatus: "运行状态",
    locationType: "位置类型",
    eqptRange: "设备量程",
    eqptSteelSeal: "设备钢印号",
    startUseTime: "启用日期",
    nextVerifyTime: "下次校表时间",
    warehouseTime: "入库时间",
    eqptOriginalCode: "新表底码",
    installationTime: "安装时间",
    installationStatus: "安装状态",
    runingModel: "运行模式",
    controllerCode: "控制器编号",
    signalIndex: "信号(1-31)",
    address: "地址全称",
    remark: "备注",
    chargeId: "收费ID",
    adjustmentCycle: "调节间隔时间",
    recentLoginTime: "最近登录时间",
    recentActiveTime: "最近活跃时间",
    recentOfflineTime: "最近离线时间",
    onlineStatus: "在线状态",
    collectorName: "名称",
    collectorModel: "型号",
    networkMode: "联网方式",
    networkingMode: "联网方式",
    dataTransMode: "数据传输方式",
    heartCycle: "心跳周期",
    cmdStatus: "指令状态",
    surplusFlow: "剩余流量",
    maxRechargeOnce: "囤积量",
    alarmAmount: "报警量",
    loginOfflineTimeType: "时间类型",
    loginOfflineTime: "时间",
    monthTime: "日期",
    monthlyWaterConsumption: "月用水量",
    longitude: "经度",
    latitude: "纬度",
    coolingQuantity: "冷量",
    heatQuantity: "热量",
    heatPower: "热功率",
    fluidVelocity: "流体速度",
    readingHeat: "正向累计热量",
    reverseHeat: "反向累计热量",
    netFlow: "净流量",
    netHeat: "净热量",
    waterOutPressure: "回水压力",
    waterInPressure: "供水压力",
    powerOntime: "上电时间",
    instantaneousHeat: "瞬时热量",
    warningName: "警告名称",
    warningType: "警告类型",
    warningValue: "警告阀值",
    warningCode: "告警编码",
    warningDesc: "告警内容",
    warningValueUnit: "单位",
    warningConfRemark: "警告配置说明",
    productModel: "产品型号",
    productProtpcol: "产品协议",
    productBatch: "产品批次号",
    subsequentOperation: "后续操作",
    warningQuantity: "预警量",
    exceptionName: "异常名称",
    exceptionDesc: "异常描述",
    exceptionCode: "异常编码",
    exceptionCreatetime: "创建时间",
    protocolName: "协议名称",
    protocolCode: "协议编码",
    protocolNetworkingMode: "联网方式",
    protocolCommunicateServe: "通讯服务器",
    protocolFacturer: "协议厂家",
    protocolAdaptationDevice: "适配设备",
    protocolSupportOperation: "支持列操作",
    protocolDocument: "文件",
    enabledTime: "启用时间",
    protocolMultipleOperation: "支持多选操作",
    imei: "imei",
    userAccount: "账户",
    userName: "名称",
    userGender: "性别",
    truthEqptCode: "设备号",
    targetEqptCode: "伪装设备号",
    targetEqptProtocol: "伪装设备协议",
    targetCollectorId: "采集器号",
    targetCollectorProtocol: "采集器协议",
    collectorProtocol: "采集器协议",
    targetIp: "服务器ip",
    port: "端口号",
    enable: "启用",
    powerCapacitanceVoltage: "电容电压",
    outsideTemperature: "环境温度",
    valveStatusAfterPowerDown: "掉电阀门状态",
    waterOutTemperatureThresholdSet: "回温阈值",
    pressureDifferenceThresholdSet: "压差阈值",
    pressureDifferenceSet: "压差设定值",
    openDegreeThresholdSet: "开度阈值",
    openDegreeSet: "开度设定值",
    exceptionDisposeTime: "处置时间",
    exceptionDisposePerson: "责任人",
    exceptionType: "类型",
    timeStart: "开始时间",
    timeEnd: "结束时间",
    count: "数量",
    statisticstimeStart: "统计开始时间",
    statisticstimeEnd: "统计结束时间",
    valveOpenTime: "累计开阀时间",
    valveStatusDetail: "阀门状态详情",
    uploadServer: "服务器地址",
    dataCollectionFrequency: "数据采集频率",
    baudRate: "波特率",
    parityBit: "校验位",
    dataBit: "数据位",
    stopBit: "停止位",
    dataTransModel: "数据中转方式",
    eqptPicture: "设备图片",
    confName: "采集策略名称",
    retryTimes: "重试次数",
    cmdReply: "指令回复",
    failReason: "失败原因",
    nodeName: "名称",
    signName: "短信签名",
    signType: "类型",
    templateCode: "短信模板",
    templateName: "模板名称",
    templateContent: "模板内容",
    longitude: "经度",
    latitude: "纬度",
    lockingStatus: "锁定状态",
    phoneNumber: "手机号",
    totalCount: "发送总数",
    successCount: "发送成功",
    failCount: "发送失败",
    otherCount: "未响应",
    sendContent: "发送内容",
    tenantName: "租户名称",
    tenantDescription: "租户描述",
    contactName: "联系人名称",
    contactPhone: "联系人手机号",
    tenantCreatetime: "租户创建时间",
    applyStatus: "申请状态",
    auditStatus: "审核状态",
    applyTime: "申请时间",
    templateRemark: "说明",
    reason: "备注",
    templateType: "类型",
    reportTime: "状态报告时间",
    errorCode: "错误码",
    errorMessage: "错误信息",
    deviceStatus: "注册状态",
    netStatus: "在线状态",
    registerTime: "注册时间",
    activateTime: "激活时间",
    recentOnlineTime: "最近在线时间",
    recentOfflineTimeNew: "最近离线时间",
    createTime: "创建时间",
    creatorName: "创建人",
    nbEqptBatch: "批次号",
    registerErrRemark: "注册失败详情",
    valveActionCurrent: "阀门动作电流",
    weatherLocation: "气温位置",
    // 工单
    woId: "工单id",
    woDescription: "工单描述",
    woCreatorName: "创建人",
    woCreateTime: "创建时间",
    woStatus: "工单状态",
    woDisposerName: "处置人",
    woDisposerPhone: "处置人电话",
    woDisposeTime: "处置时间",
    woDisposeSolution: "解决方案",
    woDisposeAttachment: "附件信息",
    woAuditorName: "审核人",
    woAuditTime: "审核时间",
    woAuditOpinion: "审核意见",
    woCompleteTime: "完成时间",
    woResource: "工单来源",
    priceName: "名称",
    priceType: "水价类型",
    costType: "费用类型",
    constantUnitPrice: "固定水价",
    chargeCycle: "计费周期",
    chargeName: "计费名称",
    costAmount: "金额",
    companyName: "公司",
    companyUnitName: "部门",
    areaName: "小区",
    buildingName: "楼栋",
    unitName: "单元",
    familyName: "户",
    accountUserName: "户主姓名",
    userPhone: "电话",
    // 用热配置
    triggerMode: "用热数据触发方式",
    calculationMode: "用热数据计算方式",
    customDataInstantaneousFlow: "自定义流速",
    timerFrequency: "频次",
    timerFrequencyUnit: "频次单位",

    inOutTemperatureDifference: "供回水温差",
    dispatchMode: "调度模式",
    instantaneousFlowSet: "瞬时流量目标设定值",
  },
  // view 名称
  table: {
    noDataText: "暂无数据",
    noFilteredDataText: "无筛选数据文本",
  },
};
